.sec-5-main {
    height: max-content;
    padding-bottom: 4rem;
    background: #0E0A0A;
    scroll-margin-top: 4rem;
}
.sec-5-title {
    color: #FFF;
    font-family: Inter;
    font-weight: 700;
    font-size: 42px;
    font-style: normal;
    line-height: normal;
    padding-top: 5rem;
    margin-bottom: 4rem;
}
.mantine-Carousel-control {
    background: #ffffff00;
    border: 0;
    box-shadow: none;
}
.sec-5-carousel-main {
    width: 380px;
    height: 550px;
    flex-shrink: 0;
    position: relative;
    border-radius: 25px;
}
.sec-5-carousel-title {
    color: #FFF;
    font-family: Inter;
    font-weight: 700;
    font-size: 32px;
    font-style: normal;
    line-height: normal;
    display: block;
    width: max-content;
    margin-bottom: 3rem;
}
.sec-5-carousel-description {
    color: #FFF;
    font-family: Inter;
    font-weight: 400;
    font-size: 18px;
    font-style: normal;
    line-height: normal;
    text-align: center;
    margin-bottom: 8px;
}
.sec-5-carousel-price {
    color: #FFF;
    font-family: Inter;
    font-weight: 700;
    font-size: 32px;
    font-style: normal;
    line-height: normal;
    text-align: center;
    margin-bottom: 1rem;
}
.sec-5-carousel-button {
    height: 48px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #F9CA24;
    width: calc(100% - 40px);
    transition: all 0.4s ease;
}
.sec-5-carousel-button-text {
    color: #0E0A0A;
    font-family: Inter;
    font-weight: 700;
    font-size: 18px;
    font-style: normal;
    line-height: normal;
}
.sec-5-carousel-button:hover {
    background: #ffffff;
}
.sec-5-group {
    margin-top: 3rem;
}
.sec-5-description-box {
    width: 3rem;
    height: 3rem;
    background: #900020;
    margin-right: 1rem;
}
#sec-5-description-box-2 {
    background: #EB4D4B;
}
.sec-5-description-label {
    color: #FFF;
    font-family: Inter;
    font-weight: 700;
    font-size: 32px;
    font-style: normal;
    line-height: normal;
    text-align: center;
    margin-top: 4px;
}
.sec-5-description {
    color: #FFF;
    font-family: Inter;
    font-weight: 700;
    font-size: 24px;
    font-style: normal;
    line-height: normal;
}
@media screen and (max-width: 900px) {
    .sec-5-description {
        font-size: 18px;
        width: max-content;
    }
    .sec-5-description-box {
        width: 2.5rem;
        height: 2.5rem;
        margin-right: 0;
    }
    .sec-5-description-label {
        font-size: 24px;
    }
}
@media screen and (max-width: 768px) {
    .sec-5-main {
        padding-bottom: 2rem;
    }
    .sec-5-group {
        margin-top: 2rem;
    }
    .sec-5-title {
        text-align: center;
        font-size: 2rem;
        display: block;
        width: 400px;
        margin-left: auto;
        margin-right: auto;
        padding-top: 3rem;
    }
    .sec-5-description {
        font-size: 16px;
        width: 350px;
    }
}
@media screen and (max-width: 520px) {
    .sec-5-carousel-main {
        width: 320px;
        height: 450px;
    }
    .sec-5-carousel-title {
        font-size: 24px;
    }
    .sec-5-carousel-description {
        font-size: 14px;
    }
    .sec-5-carousel-price {
        font-size: 24px;
        margin-bottom: 8px;
    }
    .sec-5-carousel-button {
        height: 2.5rem;
    }
    .sec-5-carousel-button-text {
        font-size: 14px;
    }
    .tabler-icon-arrow-bar-to-right, .tabler-icon-arrow-bar-to-left {
        width: 2rem;
        height: 2rem;
    }
    .sec-5-title {
        font-size: 24px;
        width: 100%;
    }
    .sec-5-description {
        width: 250px;
        font-size: 12px;
    }
}
@media screen and (max-width: 375px) {
    .sec-5-carousel-main {
        width: 290px;
    }
    .sec-5-description-box {
        width: 1.5rem;
        height: 1.5rem;
    }
    .sec-5-description-label {
        font-size: 12px;
    }
}