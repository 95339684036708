.sec-3-main {
    background: #0E0A0A;
    height: max-content;
    scroll-margin-top: 8.5rem;
}
.sec-3-flex {
    width: 580px;
    padding-top: 4rem;
    padding-bottom: 4rem;
}
.sec-3-title {
    color: #FFF;
    font-family: Inter;
    font-weight: 700;
    font-size: 42px;
    font-style: normal;
    line-height: normal;
    width: 980px;
    margin-bottom: 4rem;
}
.mantine-TextInput-input {
    height: 4rem;
    margin-bottom: 2rem;
    padding-left: 2rem;
    color: #0E0A0A;
    font-family: Inter;
    font-weight: 400;
    font-size: 24px;
    font-style: normal;
    line-height: normal;
    background: #FFF;
    border-radius: 10px;
}
.sec-3-button {
    width: 100%;
    height: 72px;
    flex-shrink: 0;
    border-radius: 15px;
    background: #900020;
    margin-top: 4rem;
    transition: all 0.4s ease;
}
.sec-3-button:hover {
    background-color: #EB4D4B;
}
.sec-3-button-text {
    color: #FFF;
    font-family: Inter;
    font-weight: 700;
    font-size: 20px;
    font-style: normal;
    line-height: normal;
}
.sec-3-description {
    color: #FFF;
    font-family: Inter;
    font-weight: 500;
    font-size: 18px;
    font-style: normal;
    line-height: normal;
    width: 480px;
    margin-top: 24px;
}
@media screen and (max-width: 1180px) {
    .sec-3-title {
        font-size: 2rem;
        width: 800px;
    }
}
@media screen and (max-width: 768px) {
    .sec-3-flex {
        width: 100%;
    }
    .sec-3-title {
        width: 90%;
        font-size: 24px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }
    .mantine-TextInput-input {
        height: 3.5rem;
        margin-bottom: 1.5rem;
        font-size: 16px;
    }
    .sec-3-icon {
        width: 2rem;
        height: 2rem;
    }
    .sec-3-button {
        margin-top: 1rem;
        height: 4rem;
        border-radius: 5px;
    }
    .sec-3-description {
        text-align: center;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        font-size: 16px;
    }
}

@media screen and (max-width: 520px) {
    .sec-3-flex {
        padding: 2rem 0 2rem 0;
    }
    .sec-3-title {
        font-size: 16px;
        width: 100%;
        margin-bottom: 2rem;
    }
    .sec-3-description {
        font-size: 14px;
        width: 100%;
    }
    .sec-3-button {
        height: 3rem;
    }
    .sec-3-button-text {
        font-size: 16px;
    }
}