.sec-1-main {
    height: max-content;
    font-family: Inter;
    font-weight: 600;
}
.sec-1-background {
    height: 100%;
    background-color: rgba(14, 10, 10, 0.92);
}
.sec-1-title {
    color: #FFF;
    font-size: 4rem;
    line-height: normal;
    font-family: Inter;
    font-weight: 700;
    font-style: normal;
    width: 800px;
    padding-top: 200px;
}
.sec-1-title span {
    background-color: #900020;
    color: #FFF;
    padding: 4px 8px;
}
.sec-1-subtitle {
    color: #FFF;
    font-family: Inter;
    font-weight: 300;
    font-size: 32px;
    font-style: normal;
    line-height: normal;
    width: 700px;
    margin-top: 2rem;
}
.sec-1-button {
    width: 480px;
    height: 80px;
    flex-shrink: 0;
    border-radius: 15px;
    background: #900020;
    margin-top: 4rem;
    margin-bottom: 230px;
    transition: all 0.4s ease;
}
.sec-1-button:hover {
    background-color: #EB4D4B;
}
.sec-1-button-text {
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-weight: 700;
    font-size: 20px;
    font-style: normal;
    line-height: normal;
}
@media screen and (max-width: 1180px) {
    .sec-1-title {
        font-size: 2.5rem;
        width: 700px;
    }
    .sec-1-subtitle {
        font-size: 24px;
        max-width: 600px;
    }
    .sec-1-button {
        width: 420px;
        height: 4rem;
    }
}
@media screen and (max-width: 768px){
    .sec-1-title {
        text-align: center;
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;
        font-size: 28px;
        padding-top: 8rem;
    }
    .sec-1-title span {
        padding: 2px 4px;
    }
    .sec-1-subtitle {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        margin-top: 1rem;
        max-width: 500px;
        font-size: 20px;
    }
    .sec-1-button {
        width: 100%;
        margin-bottom: 8rem;
        border-radius: 10px;
        height: 4rem;
    }
}
@media screen and (max-width: 520px) {
    .sec-1-title {
        font-size: 24px;
        max-width: 320px;
    }
    .sec-1-subtitle {
        font-size: 16px;
        max-width: 320px;
    }
    .sec-1-button {
        border-radius: 5px;
        height: 3rem;
        margin-bottom: 4rem;
    }
    .sec-1-button-text {
        font-size: 16px;
    }
}
@media screen and (max-width: 375px) {
    .sec-1-title {
        font-size: 20px;
        max-width: 270px;
    }
    .sec-1-subtitle {
        max-width: 270px;
    }
}