.header-main {
    position: fixed;
    top: 0;
    width: 100%;
    height: 72px;
    background: #0E0A0A;
    z-index: 10000;
}
.header-logo {
    width: 4rem;
    height: 4rem;
}
.header-links {
    color: #FFF;
    font-family: Inter;
    font-weight: 600;
    line-height: normal;
}
.telephone {
    color: #FFF;
    font-family: Inter;
    font-weight: 400;
    font-size: 18px;
    line-height: normal;
    margin: 8px 0 0 32px;
    transition: all 0.25s ease;
}
.telephone:hover {
    color: #900020;
}
.header-links-m {
    color: #FFF;
    font-family: Inter;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    font-size: 24px;
    margin-bottom: 24px;
    transition: all 0.25s ease;
}
.header-links-m:hover {
    color: #900020;
}
.hoverUnderline {
    position: relative;
    display: inline-block;
    text-decoration: none;
    margin-left: 2rem;
    margin-top: 0.5rem;
  }
  
  .hoverUnderline p {
    position: relative;
    display: inline;
    margin: 0;
    padding: 0;
    text-decoration: none;
    transition: all 0.3s ease-in-out; /* Плавная анимация */
  }
  
  .hoverUnderline p:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #FFF; /* Цвет подчеркивания */
    bottom: 0;
    left: 0;
    transform: scaleX(0); /* Начальное состояние без подчеркивания */
    transform-origin: bottom right;
    transition: transform 0.3s ease-in-out; /* Плавная анимация */
  }
  
  .hoverUnderline p:hover:after {
    transform: scaleX(1); /* Подчеркивание при наведении */
    transform-origin: bottom left;
  }

.burger {
    display: none;
}
.mantine-Drawer-content {
    background-color: #0E0A0A;
}
.mantine-Drawer-header {
    background: #0E0A0A;
}
.mantine-Drawer-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 6rem;
}
.telephone-mobile {
    color: #FFF;
    position: absolute;
    bottom: 0;
    margin-bottom: 4rem;
    font-size: 24px;
    font-family: Inter;
    font-weight: 400;
    transition: all 0.25s ease;
}
.telephone-mobile:hover {
    color: #900020;
}
@media screen and (max-width: 790px) {
    .burger {
        display: block;
        margin-top: 1rem;
    }
    .hoverUnderline, .telephone {
        display: none;
    }
    .header-logo {
        width: 3rem;
        height: 3rem;
        margin-top: 0.5rem;
    }
}
  