.sec-8-main {
    background: #FFF;
    height: max-content;
    scroll-margin-top: 5rem;
}
.sec-8-title {
    color: #0E0A0A;
    font-size: 42px;
    font-family: Inter;
    font-weight: 700;
    line-height: normal;
}
.sec-8-image {
    width: 580px;
    height: 580px;
}
.sec-8-flex-title {
    color: #0E0A0A;
    font-size: 36px;
    font-family: Inter;
    font-weight: 700;
    line-height: normal;
    max-width: 580px;
    margin-bottom: 4rem;
}
.sec-8-button {
    width: 580px;
    height: 4rem;
    border-radius: 15px;
    margin-bottom: 2rem;
    transition: all 0.25s ease;
}
.sec-8-button:hover {
    transform: scale(1.05)
}
.sec-8-button-text {
    color: #FFF;
    font-family: Inter;
    font-weight: 400;
    font-size: 18px;
}

@media screen and (max-width: 1220px) {
    .sec-8-button, .sec-8-flex-title {
        width: 540px;
    }
}
@media screen and (max-width: 1180px) {
    .sec-8-image {
        width: 450px;
        height: 450px;
    }
    .sec-8-flex-title {
        font-size: 2rem;
        width: 480px;
        margin-bottom: 1rem;
    }
    .sec-8-button {
        width: 480px;
        margin-bottom: 1rem;
    }
}
@media screen and (max-width: 990px) {
    .sec-8-image {
        margin-left: auto;
        margin-right: auto;
    }
    .sec-8-flex {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
    .sec-8-button {
        width: 100%;
        display: block;
    }
    .sec-8-flex-title {
        width: 100%;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }
}
@media screen and (max-width: 768px) {
    .sec-8-title {
        font-size: 2rem;
    }
    .sec-8-flex {
        width: 100%;
    }
    .sec-8-container {
        padding-top: 3rem;
        padding-bottom: 3rem;
    }
    .sec-8-group {
        margin-top: 2rem;
    }
}
@media screen and (max-width: 520px) {
    .sec-8-image {
        width: 300px;
        height: 300px;
    }
    .sec-8-flex-title {
        font-size: 24px;
    }
    .sec-8-button {
        height: 3rem;
        border-radius: 5px;
    }
    .sec-8-button-text {
        font-size: 16px;
    }
}
@media screen and (max-width: 425px) {
    .sec-8-flex-title {
        font-size: 18px;
    }
    .sec-8-title {
        font-size: 24px;
    }
    .sec-8-container {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
}