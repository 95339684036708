.animation-container {
  position: relative;
  width: 100%;
  height: 314px; /* Высота контейнера анимации */
  background: #900020;
  overflow: hidden;
}
.animation-title {
  color: #FFF;
  font-family: Inter;
  font-weight: 700;
  font-size: 80px;
  font-style: normal;
  line-height: normal;
}  
.text-animation {
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
}
.text-animation-title {
  margin-top: 3rem;
}
.text-animation-title-2 {
  margin-top: 11rem;
}
@media screen and (max-width: 768px) {
  .animation-container {
    height: 170px;
  }
  .animation-title {
    font-size: 42px;
  }
  .text-animation-title {
    margin-top: 1rem;
  }
  .text-animation-title-2 {
    margin-top: 6rem;
  }
}