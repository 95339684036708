.footer-main {
  background: #900020;
  height: max-content;
  font-family: Inter;
}
.footer-logo {
  width: 180px;
  height: 180px;
}
.footer-underlogo {
  font-size: 18px;
  color: #FFF;
  line-height: normal;
  max-width: 338px;
  margin-top: 1rem;
}
.footer-links-title {
  font-size: 20px;
  color: #FFF;
  font-family: Inter;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 24px;
}
.footer-links {
  color: #FFF;
  font-family: Inter;
  font-weight: 300;
  line-height: normal;
  margin-bottom: 12px;
  transition: all 0.25s ease;
}
.footer-links:hover {
  opacity: 0.7;
}
.footer-rights {
  font-family: Inter;
  font-weight: 300;
  line-height: normal;
}

@media screen and (max-width: 960px) {
  .footer-logo {
    width: 90px;
    height: 90px;
  }
  .footer-underlogo {
    width: 200px;
    font-size: 16px;
  }
  .footer-grid {
    width: 65%;
  }
  .footer-group {
    padding-top: 4rem;
    margin-bottom: 2rem;
  }
  .footer-group-2 {
    padding-bottom: 4rem;
    margin-top: 2rem;
  }
}
@media screen and (max-width: 768px) {
  .footer-grid {
    display: none;
  }
  .footer-logo {
    margin-left: auto;
    margin-right: auto;
  }
  .footer-underlogo {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  .footer-flex-logo {
    width: 100%;
  }
}
@media screen and (max-width: 568px){
  .footer-rights {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
  .footer-links-group {
    margin-left: auto;
    margin-right: auto;
  }
}