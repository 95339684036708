.sec-6-title {
    color: #0E0A0A;
    font-family: Inter;
    font-weight: 700;
    font-size: 42px;
    font-style: normal;
    line-height: normal;
    width: 300px;
    padding-top: 5rem;
}
.sec-6-title-block {
    width: 280px;
    height: 24px;
    flex-shrink: 0;
    margin-top: 8px;
    background: #900020;
    margin-bottom: 6rem;
}
.sec-6-box {
    max-width: 780px;
}
.sec-6-collapse-main {
    height: 4rem;
    background: #900020;
    border-radius: 10px 10px 0 0;
    margin-top: 24px;
   
}
.sec-6-collapse-main:hover {
    background: #900020;
}
.sec-6-collapse-group {
    width: 780px;
}
.sec-6-collapse-title {
    color: #FFF;
    font-family: Inter;
    font-weight: 700;
    font-size: 24px;
    font-style: normal;
    line-height: normal;
}
.sec-6-collapse-content {
    width: 818px;
    background: #900020;
    border-radius: 0 0 10px 10px;
}
.sec-6-collapse-content-text {
    color: #FFF;
    font-family: Inter;
    font-weight: 400;
    font-size: 18px;
    font-style: normal;
    line-height: normal;
    padding: 2rem;
}
.red-divider-box {
    height: 4rem;
    background: #900020;
}
.red-divider-text {
    color: #FFF;
    font-family: Inter;
    font-weight: 500;
    font-size: 24px;
    font-style: normal;
    line-height: normal;
    letter-spacing: 2.4px;
    padding-top: 1rem;
}

@media screen and (max-width: 925px) {
    .sec-6-box, .sec-6-collapse-main, .sec-6-collapse-content {
        max-width: 700px;
        margin-left: auto;
        margin-right: auto;
    }
    .sec-6-collapse-title {
        font-size: 18px;
    }
    .sec-6-collapse-content {
        width: 100%;
    }
    .red-divider-text {
        font-size: 18px;
        padding-top: 1.5rem;
    }
}
@media screen and (max-width: 768px) {
    .sec-6-title {
        font-size: 2rem;
        padding-top: 4rem;
    }
    .sec-6-title-block {
        margin-bottom: 2rem;
        width: 200px;
        height: 1rem;
    }
    .sec-6-box, .sec-6-collapse-main {
        width: 100%;
    }
    .sec-6-collapse-title {
        font-size: 16px;
        width: 200px;
    }
    .sec-6-collapse-icon {
        width: 24px;
        height: 24px;
    }
    .sec-6-collapse-content-text {
        font-size: 16px;
        padding: 0 2rem 1rem 2rem;
    }
    .red-divider-text {
        font-size: 16px;
        padding-top: 1rem;
    }
    .red-divider-box {
        height: max-content;
    }
}
@media screen and (max-width: 420px) {
    .sec-6-title {
        padding-top: 2rem;
        font-size: 24px;
        width: 200px;
    }
    .sec-6-title-block {
        width: 150px;
        height: 8px;
    }
    .sec-6-main {
        padding-bottom: 2rem;
    }
    .sec-6-collapse-title {
        font-size: 12px;
    }
}
@media screen and (max-width: 325px) {
    .sec-6-collapse-title {
        font-size: 11px;
    }
}