.sec-2-main {
    height: max-content;
    background: #FFF;
    scroll-margin-top: 4rem;
}
.sec-2-group {
    padding-top: 188px;
    margin-bottom: 188px;
}
.sec-2-logo {
    width: 380px;
    height: 380px;
    flex-shrink: 0;
    border-radius: 50px;
    margin-right: 8rem;
}
.sec-2-title {
    color: #0E0A0A;
    font-family: Inter;
    font-weight: 700;
    font-size: 32px;
    font-style: normal;
    line-height: normal;
    width: 536px;
}
.sec-2-subtitle {
    color: #0E0A0A;
    font-family: Inter;
    font-weight: 400;
    font-size: 24px;
    font-style: normal;
    line-height: normal;
    width: 536px;
}
.sec-2-button {
    width: 536px;
    height: 66px;
    flex-shrink: 0;
    transition: all 0.25s ease;
}
.sec-2-button:hover {
    transform: scale(1.1);
}
.sec-2-button-text {
    color: #FFF;
    font-family: Inter;
    font-weight: 400;
    font-size: 18px;
    font-style: normal;
    line-height: normal;
}
@media screen and (max-width: 1180px) {
    .sec-2-group {
        padding-top: 9rem;
        margin-bottom: 9rem;
    }
    .sec-2-logo {
        margin-right: 2rem;
        width: 280px;
        height: 280px;
    }
    .sec-2-title {
        font-size: 24px;
        width: 400px;
    }
    .sec-2-subtitle {
        font-size: 18px;
        width: 400px;
    }
    .sec-2-button {
        width: 400px;
    }
}
@media screen and (max-width: 768px) {
    .sec-2-group {
        padding-top: 6rem;
        margin-bottom: 6rem;
    }
    .sec-2-logo {
        margin-right: 0;
    }
    .sec-2-title {
        text-align: center;
        width: 70%;
        margin-left: auto;
        margin-right: auto;
    }
    .sec-2-subtitle {
        text-align: center;
        width: 70%;
        margin-left: auto;
        margin-right: auto;
    }
    .sec-2-button {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
}
@media screen and (max-width: 520px){
    .sec-2-group {
        padding-top: 4rem;
        margin-bottom: 4rem;
    }
    .sec-2-logo {
        width: 220px;
        height: 220px;
    }
    .sec-2-title {
        width: 90%;
        font-size: 18px;
    }
    .sec-2-subtitle {
        width: 90%;
        font-size: 16px;
    }
    .sec-2-button {
        width: 100%;
        height: 48px;
    }
    .sec-2-button-text {
        font-size: 16px;
    }
}