.sec-7-main {
    height: max-content;
    background: #0E0A0A;
    scroll-margin-top: 4rem;
}
.sec-7-title {
    font-size: 42px;
    font-family: Inter;
    font-weight: 700;
    color: #FFF;
    line-height: normal;
}
.portfolio-image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 580px;
    height: 390px;
    transition: all 0.25s ease;
}
.portfolio-image:hover {
    transform: scale(1.1);
}
.portfolio-title {
    font-size: 20px;
    text-align: center;
    font-family: Inter;
    font-weight: 700;
    color: #FFF;
    margin-top: 24px;
}
.portfolio-button {
    width: 280px;
    height: 42px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    background: #900020;
    margin-top: 18px;
    border-radius: 10px;
}
.portfolio-button-text {
    font-size: 18px;
    font-family: Inter;
    font-weight: 700;
    color: #FFF;
}
@keyframes portfolio-btn-color {
    from {
        background: #900020;
    }
    to {
        background: #EB4D4B;
    }
}
.portfolio-button:hover {
    animation: portfolio-btn-color 600ms forwards;
}

@media screen and (max-width: 1280px) {
    .portfolio-image {
        width: 480px;
    }
}
@media screen and (max-width: 1180px) {
    .portfolio-image, .portfolio-main {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }
    .portfolio-main {
        margin-top: 3rem;
    }
    .sec-7-group {
        margin-top: 0;
    }
    .sec-7-container {
        padding: 4rem 0;
    }
    .sec-7-title {
        text-align: center;
    }
}
@media screen and (max-width: 768px){
    .sec-7-title {
        font-size: 2rem;
    }
    .portfolio-image {
        height: 300px;
        width: 95%;
    }
    .portfolio-title {
        font-size: 18px;
    }
    .portfolio-icon {
        width: 2rem;
        height: 2rem;
    }
    .portfolio-button {
        margin-top: 0.5rem;
    }
}
@media screen and (max-width: 520px){
    .portfolio-image {
        height: 220px;
    }
    .sec-7-container {
        padding: 2rem 0;
    }
    .sec-7-title {
        font-size: 24px;
    }
    .portfolio-button {
        width: 240px;
        height: 2.5rem;
    }
    .portfolio-button-text {
        font-size: 16px;
    }
}