.sec-4-main {
    height: max-content;
    background: #FFF;
}
.sec-4-title {
    color: #0E0A0A;
    font-family: Inter;
    font-weight: 700;
    font-size: 42px;
    font-style: normal;
    line-height: normal;
    width: 869px;
    padding-top: 5rem;
    margin-bottom: 6rem;
}
.sec-4-block {
    width: 280px;
    height: 280px;
    flex-shrink: 0;
    border-radius: 15px;
    position: relative;
    text-decoration: none;
    cursor: pointer;
}
@keyframes block-size {
    from {
        transform: scale(1);
    }
    to {
        transform: scale(1.1);
    }
}
.sec-4-block:active {
    animation: block-size 400ms forwards;
}
.sec-4-block-title {
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-weight: 700;
    font-size: 20px;
    font-style: normal;
    line-height: normal;
    width: 196px;
}
.sec-4-block-description {
    color: #ffffff;
    font-family: Inter;
    font-weight: 300;
    font-size: 16px;
    font-style: normal;
    line-height: normal;
    text-align: center;
    padding-bottom: 4rem;
}
.sec-4-description {
    color: #0E0A0A;
    text-align: center;
    font-family: Inter;
    font-weight: 500;
    font-size: 18px;
    font-style: normal;
    line-height: normal;
    width: 791px;
    margin-top: 3rem;
    padding-bottom: 3rem;
}
.sec-4-carousel {
    display: none;
    height: max-content;
}
@media screen and (max-width: 1180px) {
    .sec-4-title {
        font-size: 2rem;
        text-align: center;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
    .sec-4-block {
        width: 45%;
    }
    .sec-4-description {
        width: 90%;
    }
}
@media screen and (max-width: 768px) {
    .sec-4-title {
        font-size: 24px;
        padding-top: 2rem;
        margin-bottom: 2rem;
    }
    .sec-4-block {
        width: 280px;
        height: 280px;
        margin: 1rem 8px 0 8px;
    }
    .sec-4-block-title {
        width: 100%;
    }
    .sec-4-block-description {
        padding-bottom: 1rem;
    }
    .sec-4-description {
        margin-top: 2rem;
        padding-bottom: 2rem;
        font-size: 14px;
    }
    .sec-4-group {
        display: none;
    }
    .sec-4-carousel {
        display: block;
    }
}
@media screen and (max-width: 520px) {
    .sec-4-block {
        width: 100%;
    }
    .sec-4-block-description {
        width: 100%;
    }
    .sec-4-title {
        width: 100%;
        font-size: 22px;
    }
}