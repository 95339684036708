.contact-icon {
    cursor: pointer; 
    position: absolute;
    top: 0;
    right: 0;
    margin: 2rem;
}
.contact-title {
    font-size: 2rem;
    padding-top: 2rem;
    color: #FFF;
    font-family: Inter;
    font-weight: 700;
    text-align: center;
}
.mantine-Textarea-input {
    font-size: 24px;
    padding-left: 2rem;
    font-family: Inter;
}
.contact-button {
    background: #900020;
    width: 100%;
    height: 5rem;
    border-radius: 10px;
    margin-top: 2rem;
}
.contact-button-text {
    color: #FFF;
    font-size: 24px;
    font-family: Inter;
    font-weight: 700;
}
@keyframes button-color-contact {
    from {
        background: #900020;
    }
    to {
        background-color: #EB4D4B;
    }
}
.contact-button:hover {
    animation: button-color-contact 600ms forwards;
}
@media screen and (max-width: 768px) {
    .contact-container {
        padding: 2rem;
    }
    .contact-title {
        font-size: 24px;
        margin-bottom: 2rem;
    }
    .contact-button {
        height: 4rem;
    }
    .mantine-Textarea-input {
        font-size: 18px;
    }
    .contact-icon {
        margin: 1rem;
    }
}
@media screen and (max-width: 520px) {
    .contact-button {
        height: 3rem;
    }
    .contact-button-text {
        font-size: 18px;
    }
    .contact-container {
        padding: 1rem;
    }
}